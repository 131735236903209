import { useState } from "react";
import { useFlags } from "../../../server/optimizely";
import { useSupabase } from "../../../server/supabase/hooks";
import { notEmpty } from "../../../utils/not-empty";
import { SupabasePersonService } from "../../employees/server/supabase-person-service";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";

export function useSegmentFieldValueOptions({
  audience,
  disabled,
  segmentField,
}: {
  segmentField?: UsableAccountPersonField;
  disabled?: boolean;
  audience?: string;
}): {
  options: string[];
  loading: boolean;
} {
  const [selectedFieldOptionsState, setSelectedFieldOptionsState] = useState<{
    options: string[];
    loading: boolean;
  }>({ options: [], loading: true });

  const { flagsLoaded, flags } = useFlags("segment_condition_values_audience_filtered");

  useSupabase(
    async ({ supabase, account_id }) => {
      if (!disabled && segmentField && flagsLoaded) {
        const service = new SupabasePersonService(supabase);

        const { data: uniqueFieldsData } = await (audience &&
        flags.segment_condition_values_audience_filtered
          ? service.getUniqueValuesByAudience(account_id, segmentField.key ?? "", audience)
          : service.getUniqueValues(account_id, segmentField.key ?? ""));

        if (uniqueFieldsData) {
          setSelectedFieldOptionsState({
            loading: false,
            options: uniqueFieldsData
              .map((field) => field.value)
              .filter(notEmpty)
              .sort(),
          });
        }
      } else {
        setSelectedFieldOptionsState({ options: [], loading: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, audience, flagsLoaded, flags, JSON.stringify(segmentField)],
  );

  return selectedFieldOptionsState;
}
