import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FC, ReactElement, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Trans } from "react-i18next";
import { useSupabase } from "../../../../server/supabase/hooks";
import { ChannelProps } from "../../../channels/components/channel-card";
import { MomentFormMoment } from "../../../moment/types/moment-form-moment";
import { SupabaseSegmentService } from "../../server/supabase-segment-service";
import { Segment } from "../../types";

export const SegmentManagerRecipientCount: FC<{ channels: ChannelProps[] }> = ({ channels }) => {
  const [segmentCount, setSegmentCount] = useState<{
    employee: number;
    recipient: { channel: ChannelProps; recipientCount: number }[];
  }>();
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);

  const { watch } = useFormContext<Pick<MomentFormMoment, "segment">>();
  const [currentSegment] = watch(["segment"]);

  useSupabase(
    async ({ supabase }) => {
      if (!currentSegment) return;

      const supabaseService = new SupabaseSegmentService(supabase);
      const [employeeCount, ...recipientCounts] = await Promise.all([
        getSegmentCount(supabaseService, currentSegment, undefined),
        ...channels.map(async (c) => ({
          recipientCount: (await getSegmentCount(supabaseService, currentSegment, c.name as string))
            .segmentCount,
          channel: c,
        })),
      ]);

      setSegmentCount({
        employee: employeeCount.segmentCount,
        recipient: recipientCounts,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSegment, currentSegment?.operation, currentSegment?.conditions, channels],
  );

  if (!segmentCount) return null;

  const constructText = (): ReactElement => {
    return (
      <Tooltip title="Click for more info about the Segment">
        <>
          <Trans i18nKey={"thereAreCountPeople"} values={{ count: segmentCount.employee }}>
            <span
              style={
                segmentCount.employee > 0 ? { cursor: "pointer", color: "#5048e5" } : undefined
              }
              role="link"
              tabIndex={0}
            ></span>
          </Trans>
        </>
      </Tooltip>
    );
  };

  return (
    <>
      <Stack spacing={0} alignItems="flex-start">
        <Accordion
          expanded={accordionExpanded}
          onChange={(_, expanded) => {
            if (segmentCount.employee === 0 && expanded) return;

            setAccordionExpanded(expanded);
          }}
          elevation={0}
          sx={{
            "&:before": { display: "none" },
            mt: 0,
          }}
        >
          <AccordionSummary
            expandIcon={segmentCount.employee > 0 ? <ExpandMore color="primary" /> : undefined}
            sx={{ boxShadow: "none", "&:hover": { backgroundColor: "action.hover" } }}
          >
            <Typography fontStyle="italic">{constructText()}</Typography>
          </AccordionSummary>
          {segmentCount.employee > 0 && (
            <AccordionDetails sx={{ pt: 0 }}>
              <Stack spacing={0} alignItems="flex-start">
                <SegmentByChannel channels={segmentCount.recipient} />
              </Stack>
            </AccordionDetails>
          )}
        </Accordion>
      </Stack>
    </>
  );
};

const SegmentByChannel: FC<{
  channels: { channel: ChannelProps; recipientCount: number }[];
}> = ({ channels }) => {
  return (
    <List sx={{ p: 0 }}>
      {channels.map(({ channel, recipientCount: count }) => (
        <ListItem key={channel.name} sx={{ py: 0 }}>
          <ListItemIcon>
            <Tooltip placement="left" title={channel.buttonText}>
              {channel.iconSmall()}
            </Tooltip>
          </ListItemIcon>
          <ListItemText>{`${count} recipient${count !== 1 ? "s" : ""}`}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

const getSegmentCount = async (
  supabaseService: SupabaseSegmentService,
  segment: Segment,
  channel: string | undefined,
): Promise<{ segmentCount: number }> => {
  const { count: segmentCount, error: segmentCountError } = await supabaseService.getUnsavedCount(
    segment,
    false,
    channel,
    null, // This is fetching number in segment, outside of any connections
  );

  if (segmentCount === null || segmentCountError) throw new Error("Error updating segment count");
  return { segmentCount };
};
